import { Rive } from '@rive-app/canvas';
import '../../../css/views/errorPage.pcss';

(function initErrorPage(): void {
    const canvas = document.getElementById('deer-animation') as HTMLCanvasElement | null;

    if (!(canvas instanceof HTMLCanvasElement)) {
        return;
    }

    const rive = new Rive({
        src: '/assets/animations/deer_animation.riv',
        canvas: canvas,
        autoplay: true,
        onLoad: (): void => {
            rive.resizeDrawingSurfaceToCanvas();
        }
    });
})();
